@media screen and (max-width: 600px) {
  #features {
    padding: 20px;
    width:100%;
  }
  .intro h1{
    font-size: 50px!important;
  }
  .intro p{
    font-size: 15px;
  }
  .btn-custom{
    margin-bottom: 10px;
  }

  #about,
  #services,
  #testimonials,
  #team,
  #contact,
  #footer {
    width: 100%;
  }

  #portfolio {
    width: 100%;
  }
  .portfolio-items{
    /* display: flex;
    justify-self: center; */
    margin-left: 10%;
    margin-right: 10%;
  }
  .sm-mb-2{
    margin-bottom: 10%;
  }
}